
import ValidationMixin from "@/mixins/validation";
import { UserTypes, SystemUser } from "@/store/modules/user/user.types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";

const userX = namespace("User");

@Component({})
export default class EditUser extends Mixins(ValidationMixin) {
  @userX.State(UserTypes.DELETE_USER_STATE)
  public deleteUser!: DefaultState;

  @userX.State(UserTypes.USER_DATA)
  public userUser!: SystemUser;

  @userX.Mutation(UserTypes.SET_DELETE_USER_DIALOG)
  public setDeleteUser!: (deleteUser: boolean) => void;

  @userX.Action(UserTypes.DELETE_USER)
  public deleteSystemUser!: () => Promise<void>;

  public updateUserStep = 1;

  public isValid = false;

  @Ref("form") public formRef!: Validator;

  closeDeleteUserDialog(): void {
    this.setDeleteUser(false);
  }
}
